
export default async function ({ route, store, $http }) {
    store.commit('setParams', route.params)
    if (store.state.instances.length === 0) {
        let instances = await $http.$get(`${process.env.strapiBaseUri}/api/instances?populate=homePageCardImage`);
        instances = instances.data;
        instances.sort((a, b) => a.attributes.ordreHomePage - b.attributes.ordreHomePage)
        store.commit('setInstances', instances)

    }

    if (route.params.campusHP) {
        if (store.state.campusInfo.subFolderName != route.params.campusHP) {
            const instance = store.state.instances.filter((instance) => {
                return instance.attributes.subFolderName == route.params.campusHP
            })[0];

            // If we have no instance then it's probably a bad route
            if(instance) {
                let campusInfo = await $http.$get(`${process.env.strapiBaseUri}/api/${instance.attributes.prefix}-home-page?populate=
                videoDeFond,
                banniere,banniereLogo,plusCampusLogo, 
                avantageCampusLogo1,avantageCampusLogo2,avantageCampusLogo3,
                tousNosCentresImage,
                detailCampusImage`);
                campusInfo = campusInfo.data;
                campusInfo.subFolderName = route.params.campusHP
                store.commit('setCampusInfo', campusInfo)
    
                let detailInfo = await $http.$get(`${process.env.strapiBaseUri}/api/${instance.attributes.prefix}-detail-home-page?populate=
                image1CampusAvantage,image2CampusAvantage,imageCarte1,imageCarte2,imageCarte3,imageCarte4`);
                detailInfo = detailInfo.data;
                detailInfo.subFolderName = route.params.campusHP
                store.commit('setDetailInfo', detailInfo)
    
                // check in store if we have the diplomas for the campus if yes filter for one campus 
                // else call api for this campus
                // once it's done put in a new elemen of the store diploma in campus
                if (store.state.diplomas.length != 0) {
                    let allDiplomas = JSON.parse(JSON.stringify(store.state.diplomas));
                    let campusDiplomas = allDiplomas.filter((allDiplomas) => {
                        return allDiplomas.campus == instance?.attributes.prefix
                    });
    
                    store.commit('setCampusDiplomas', JSON.parse(JSON.stringify(campusDiplomas)))
                }
                else {
                    let campusDiplomas = await $http.$get(`${process.env.strapiBaseUri}/api/${instance?.attributes.prefix}-diplomas?populate=
                    banniere,iconeControleDiplome,niveauDiplome,sessions`);
                    campusDiplomas = campusDiplomas.data;
                    store.commit('setCampusDiplomas', JSON.parse(JSON.stringify(campusDiplomas)))
                }


            }
        }
    }
}