import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8bbc8f78 = () => interopDefault(import('../pages/404-error.vue' /* webpackChunkName: "pages/404-error" */))
const _5cd609a2 = () => interopDefault(import('../pages/candidats.vue' /* webpackChunkName: "pages/candidats" */))
const _52e1c244 = () => interopDefault(import('../pages/contacter.vue' /* webpackChunkName: "pages/contacter" */))
const _336afe04 = () => interopDefault(import('../pages/icfaDetails.vue' /* webpackChunkName: "pages/icfaDetails" */))
const _cfe48022 = () => interopDefault(import('../pages/mentionsLegales.vue' /* webpackChunkName: "pages/mentionsLegales" */))
const _4795ff9a = () => interopDefault(import('../pages/offreAlternanceTous.vue' /* webpackChunkName: "pages/offreAlternanceTous" */))
const _165f8700 = () => interopDefault(import('../pages/politiqueDeConfidentialite.vue' /* webpackChunkName: "pages/politiqueDeConfidentialite" */))
const _b6c98396 = () => interopDefault(import('../pages/news/_news.vue' /* webpackChunkName: "pages/news/_news" */))
const _7fc3f8e3 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3e07f65a = () => interopDefault(import('../pages/_campusHP/index.vue' /* webpackChunkName: "pages/_campusHP/index" */))
const _835d998a = () => interopDefault(import('../pages/_campusHP/campusDetail/index.vue' /* webpackChunkName: "pages/_campusHP/campusDetail/index" */))
const _a1ccee56 = () => interopDefault(import('../pages/_campusHP/contacter.vue' /* webpackChunkName: "pages/_campusHP/contacter" */))
const _30c99dda = () => interopDefault(import('../pages/_campusHP/lesAtouts.vue' /* webpackChunkName: "pages/_campusHP/lesAtouts" */))
const _7f8f09b4 = () => interopDefault(import('../pages/_campusHP/offreAlternance/index.vue' /* webpackChunkName: "pages/_campusHP/offreAlternance/index" */))
const _41185a38 = () => interopDefault(import('../pages/_campusHP/jpo/_jpo.vue' /* webpackChunkName: "pages/_campusHP/jpo/_jpo" */))
const _62c7ecb2 = () => interopDefault(import('../pages/_campusHP/landing/_landingPage.vue' /* webpackChunkName: "pages/_campusHP/landing/_landingPage" */))
const _790a09f1 = () => interopDefault(import('../pages/_campusHP/offreAlternance/_offre.vue' /* webpackChunkName: "pages/_campusHP/offreAlternance/_offre" */))
const _4f3cb4e4 = () => interopDefault(import('../pages/_campusHP/_diploma/index.vue' /* webpackChunkName: "pages/_campusHP/_diploma/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404-error",
    component: _8bbc8f78,
    name: "404-error"
  }, {
    path: "/candidats",
    component: _5cd609a2,
    name: "candidats"
  }, {
    path: "/contacter",
    component: _52e1c244,
    name: "contacter"
  }, {
    path: "/icfaDetails",
    component: _336afe04,
    name: "icfaDetails"
  }, {
    path: "/mentionsLegales",
    component: _cfe48022,
    name: "mentionsLegales"
  }, {
    path: "/offreAlternanceTous",
    component: _4795ff9a,
    name: "offreAlternanceTous"
  }, {
    path: "/politiqueDeConfidentialite",
    component: _165f8700,
    name: "politiqueDeConfidentialite"
  }, {
    path: "/news/:news?",
    component: _b6c98396,
    name: "news-news"
  }, {
    path: "/",
    component: _7fc3f8e3,
    name: "index"
  }, {
    path: "/:campusHP",
    component: _3e07f65a,
    name: "campusHP"
  }, {
    path: "/:campusHP/campusDetail",
    component: _835d998a,
    name: "campusHP-campusDetail"
  }, {
    path: "/:campusHP/contacter",
    component: _a1ccee56,
    name: "campusHP-contacter"
  }, {
    path: "/:campusHP/lesAtouts",
    component: _30c99dda,
    name: "campusHP-lesAtouts"
  }, {
    path: "/:campusHP/offreAlternance",
    component: _7f8f09b4,
    name: "campusHP-offreAlternance"
  }, {
    path: "/:campusHP/jpo/:jpo?",
    component: _41185a38,
    name: "campusHP-jpo-jpo"
  }, {
    path: "/:campusHP/landing/:landingPage?",
    component: _62c7ecb2,
    name: "campusHP-landing-landingPage"
  }, {
    path: "/:campusHP/offreAlternance/:offre",
    component: _790a09f1,
    name: "campusHP-offreAlternance-offre"
  }, {
    path: "/:campusHP/:diploma",
    component: _4f3cb4e4,
    name: "campusHP-diploma"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
